import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.1.0_react-dom@18.3.1_react@18.3.1_sass@1.77.1/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.1.0_react-dom@18.3.1_react@18.3.1_sass@1.77.1/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/atoms/DecorativeHeading/styles.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/atoms/Button/styles.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/atoms/ReadingTime/styles.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/molecules/LatestBlogEntry/styles.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/sections/CaseStudies/styles.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/sections/GridFloatingImg/item.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/sections/homepage/Roadmap/roadmapSection.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/sections/LatestBlogEntries/styles.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/sections/Team/styles.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/sections/Testimonials/styles.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/sections/GridFloatingImg/styles.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/sections/homepage/Creativity/styles.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/sections/homepage/FourGrid/styles.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/sections/homepage/Hero/styles.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/sections/homepage/Roadmap/styles.module.scss")